import "../components/cmsShared";
import "./Styles/main.scss";
import { Slick } from "@/pages/vendors/vendor.slick";
import "../components/searchBox";
import { createAniView } from "@/pages/vendors/vendor.aniView";
import "../components/baiduMap";
import "picturefill";
import "@/pages/base/divUtils";
import { qrCodeGenerator } from "../components/cmsShared/qrCode";
import { cmsGlobal } from "@/common/fhtGlobalEnv";
import { CMS_WAP_URL_ROOT } from "@/consts";
import { KnownCmsPageType } from "@/api";
import {loadContact} from "@/phone";

$(async function () {
    createAniView($(".aniview"));
    loadContact();
    const wapHomeUrl = `${CMS_WAP_URL_ROOT}/c${cmsGlobal.Cms.CompanyId}`;
    qrCodeGenerator(wapHomeUrl);

    const $bannerSlides = $(".banner-slides");
    async function initBannerSlides($bannerSlides: JQuery) {
        if ($bannerSlides.find(".banner-slides__item").length) {
            new Slick($bannerSlides, {
                dots: true,
                autoplay: true,
                autoplaySpeed: 5000,
            });
        }
        // 解决图片在slick未加载完成时闪现的问题，结合.banner-slides img的样式使用
        $bannerSlides.find("img").css("visibility", "visible");
    }
    await initBannerSlides($bannerSlides);

    new Slick($("#newproducts"), {
        dots: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    if (cmsGlobal.Cms.IsCustomDomain && cmsGlobal.KnownPage === KnownCmsPageType.CmsProductDetail) {
        import(/* webpackChunkName: "imagePhotoswipe" */ "../../pages/modules/imagePhotoswipe");
    }
});
